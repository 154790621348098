import React from 'react';
import Fade from 'react-reveal/Fade';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "../components/Typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { faUserAstronaut, faPalette, faUniversity, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'


const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    backgroundColor: "#FFEA00",
    borderRadius: 3,
    padding: 4
};

class Boxes extends React.Component {
    render() {
        return (
            <>
                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faUniversity} size="3x" />
                            <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
                                Mint Details
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Date: 3/25/22 - 19:00 UTC" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Price: 888 $STARS" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Mint location: Stargaze Launchpad" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Resales on Stargaze" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Sold out in 3 minutes" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faUserAstronaut} size="3x" />
                            <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
                                Astrobiology
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Alpha Centauri is Earth’s closest neighbor" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Proxima Centauri has 3 observed planets" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="One is in the habitable zone" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Alpha Centaurians will visit Earth soon" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
                            <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
                                Timeline
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="DAO to be formed post launch" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Validator node partnership" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="All proceeds staked and commissions to DAO" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Future airdrop to holders" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faPalette} size="3x" />
                            <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
                                Art
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="High resolution with rich lustrous details" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Beautiful PFP format" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Industry leading rarity structure" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="7 categories with up to 7 traits" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="314 unique NFTs" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>
            </>
        )
    }
}

export default Boxes
import * as React from 'react';

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Boxes from "./Wave2Boxes";
import "./Socials.css";
import {Typography} from "@mui/material";
import ProductHeroLayout2 from "./ProductHeroLayout2";


const backgroundImage = '/background2.png';

function Wave1() {
  return (
      <ProductHeroLayout2
          sxBackground={{
              background: `url(${backgroundImage}) no-repeat center center fixed`,
              WebkitBackgroundSize: 'cover',
              MozBackgroundSize: 'cover',
              OBackgroundSize: 'cover',
              backgroundSize: 'cover',
              backgroundColor: '#454547', // Average color of the background image.
              backgroundPosition: 'center',
          }}
      >
            {/* Increase the network loading priority of the background image. */}
            <img
              style={{ display: 'none' }}
              src={backgroundImage}
              alt="increase priority"
            />
            <Container sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: "column" }}>
              <Typography variant="h2" sx={{marginBottom: 10, color: "#FFFFFF", alignSelf: "center"}}>Wave 2</Typography>
              <Grid container spacing={5}>
                <Boxes />
              </Grid>
            </Container>
      </ProductHeroLayout2>
  );
}

export default Wave1;

import React from 'react';
import Fade from 'react-reveal/Fade';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter, faInstagram} from "@fortawesome/free-brands-svg-icons";

class Socials extends React.Component {
    render() {
        return (
            <ul>
                <Fade bottom>
                    <li className="twitter">
                        <a href="https://twitter.com/ACentaurians">
                            <FontAwesomeIcon className="fa fa-twitter fa-2x" icon={faTwitter} size="2x" />
                        </a>
                    </li>
                </Fade>
                <Fade bottom>
                    <li className="discord">
                        <a href="http://discord.gg/axDPPcMTCr">
                            <FontAwesomeIcon className="fa fa-twitter fa-2x" icon={faDiscord} size="2x" />
                        </a>
                    </li>
                </Fade>
                <Fade bottom>
                    <li className="twitter">
                        <a href="https://www.instagram.com/alphacentaurians/">
                            <FontAwesomeIcon className="fa fa-instagram fa-2x" icon={faInstagram} size="2x" />
                        </a>
                    </li>
                </Fade>
            </ul>
        );
    }
}

export default Socials;
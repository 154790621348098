import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Boxes from './Wave1Boxes'
import './Socials.css'
import {Typography} from "@mui/material";

function Wave1() {
  return (
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: "rgb(40 40 42)"}}
      >
        <Container sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: "column" }}>
          <Typography variant="h2" sx={{mb: 5, color: "#FFFFFF", alignSelf: "center"}}>Wave 1</Typography>
          <Grid container spacing={5}>
            <Boxes />
          </Grid>
        </Container>
      </Box>
  );
}

export default Wave1;

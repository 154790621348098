import * as React from 'react';
import ProductHero from './modules/views/ProductHero';
import Wave1 from './modules/views/Wave1';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';
import Wave2 from "./modules/views/Wave2";
import Box from "@mui/material/Box";
import Socials from "./modules/views/Socials";
import {withStyles} from "@mui/styles";
import {Typography} from "@mui/material";


const CustomColor = withStyles({
    root: {
        background: "-webkit-linear-gradient(45deg, #822FDE 16%, #34CCC3 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    }
})(Typography);

function Index() {
  return (
    <React.Fragment>
        <AppAppBar />
        <ProductHero />
        <div id="wave1" />
        <Wave1 />
        <div id="wave2" />
        <Wave2 />
        <Box style={{display: "flex", justifyContent: "center", marginBottom: 20, marginTop: 20}}>
            <CustomColor variant="h2" fontFamily="Roboto" id="metaverse">METAVERSE COMING IN 2023</CustomColor>
        </Box>
        <Box style={{marginBottom: 35, justifyContent: "center", display: "flex"}}>
            <div className="wrapper">
                <Socials />
            </div>
        </Box>
    </React.Fragment>
  );
}

export default withRoot(Index);

import ReactDOM from 'react-dom';
import Index from './Home';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
      <Routes>
          <Route path="/*" element={<Index />} />
      </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TwitterIcon from '@mui/icons-material/Twitter';
import MenuIcon from '@mui/icons-material/Menu';

export const MobileDrawer = () => {
    const [open, setOpen] = React.useState(false)

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open)
    };

    return(
        <React.Fragment>
            <IconButton onClick={toggleDrawer} color="inherit" aria-label="upload picture" component="span">
                <MenuIcon />
            </IconButton>
            <Drawer
                open={open}
                onClose={toggleDrawer}
            >
                <Box
                    role="presentation"
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                >
                    <List>
                        <ListItem button>
                            <Link
                                variant="h6"
                                underline="none"
                                href="/"
                            >
                                <ListItemText primary={"Home"} />
                            </Link>
                        </ListItem>
                        <ListItem button>
                            <Link
                                variant="h6"
                                underline="none"
                                href="https://app.stargaze.zone/marketplace/stars10v8m3lglw4vat5g3w4xj7ven7puxudnqkjwwaf9nh3alukxpmh3qm4wzwh"
                                target="_blank"
                            >
                                <ListItemText primary={"Marketplace"} />
                            </Link>
                        </ListItem>
                        <ListItem button>
                            <Link
                                variant="h6"
                                underline="none"
                                href="https://app.stargaze.zone/profile"
                            >
                                <ListItemText primary={"View Collection"} />
                            </Link>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button>
                            <ListItemIcon style={{justifyContent: 'center'}}>
                                <Link
                                    color="inherit"
                                    variant="h6"
                                    underline="none"
                                    target="_blank"
                                    href="https://twitter.com/ACentaurians"
                                >
                                    <TwitterIcon style={{color: "#00acee"}} />
                                </Link>
                            </ListItemIcon>
                            <ListItemIcon style={{justifyContent: 'center'}}>
                                <Link
                                    color="inherit"
                                    variant="h6"
                                    underline="none"
                                    target="_blank"
                                    href="http://discord.gg/axDPPcMTCr"
                                >
                                    <FontAwesomeIcon size="lg" icon={faDiscord} style={{color: "#5865F2"}} />
                                </Link>
                            </ListItemIcon>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}
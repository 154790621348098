import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import { MobileDrawer } from '../components/MobileDrawer'
import './Menu.css';

function AppAppBar() {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if(isMobile){
    return (
        <div>
          <AppBar position="fixed">
            <Toolbar sx={{ justifyContent: 'space-center'}}>
              <Link
                  variant="h6"
                  underline="none"
                  color="inherit"
                  href="/#"
                  sx={{ justifyContent: 'center', alignItems: 'center', fontSize: 24, display: 'flex' }}
              >
                <img src="/logo.png" width="270" height="50" />
              </Link>
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <MobileDrawer />
              </Box>
            </Toolbar>
          </AppBar>
          <Toolbar />
        </div>
    )
  }
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar style={{justifyContent: 'space-between', marginRight: 40, marginLeft: 40}}>
          <Link
              variant="h6"
              underline="none"
              color="inherit"
              href="/#"
              sx={{ justifyContent: 'center', alignItems: 'center', fontSize: 24, display: 'flex' }}
          >
            <img src="/logo.png" width="270" height="50" />
          </Link>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Link
                color="inherit"
                variant="h6"
                underline="none"
                target="_blank"
                className="link hover-2"
                href="/whitepaper_v2.pdf"
                sx={{marginLeft: 3}}
              >
                {'Whitepaper'}
              </Link>
            <Link
                color="inherit"
                variant="h6"
                underline="none"
                className="link hover-2"
                href="#wave1"
                sx={{marginLeft: 3}}
            >
              {'Wave 1'}
            </Link>
            <Link
                color="inherit"
                variant="h6"
                underline="none"
                className="link hover-2"
                href="#wave2"
                sx={{marginLeft: 3}}
            >
              {'Wave 2'}
            </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                target="_blank"
                className="link hover-2"
                href="https://app.stargaze.zone/marketplace/stars10v8m3lglw4vat5g3w4xj7ven7puxudnqkjwwaf9nh3alukxpmh3qm4wzwh"
                sx={{marginLeft: 3}}
              >
                {'Marketplace'}
              </Link>
              <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className="link hover-2"
                  href="#metaverse"
                  sx={{marginLeft: 3, color: '#FFEA00'}}
              >
                {'Metaverse'}
              </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;

import React from 'react';
import Fade from 'react-reveal/Fade';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "../components/Typography";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUniversity, faBattery3} from "@fortawesome/free-solid-svg-icons";


const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    backgroundColor: "#FFEA00",
    borderRadius: 3,
    padding: 4,
    color: "#000000"
};

class Boxes extends React.Component {
    render() {
        return (
            <>
                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faUniversity} size="3x" />
                            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                Mint Details
                            </Typography>
                            <List>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000", mt: 1}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Date: 9/30/22" />
                                </ListItem>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000", mt: 1}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Price: 388 $STARS" />
                                </ListItem>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000", mt: 1}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Mint location: Stargaze Launchpad" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Fade bottom>
                        <Box sx={item}>
                            <FontAwesomeIcon icon={faBattery3} size="3x" />
                            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                                3 Rounds
                            </Typography>
                            <List>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Males" />
                                </ListItem>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000"}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Females" />
                                </ListItem>
                                <ListItem disablePadding sx={{mt: 1}}>
                                    <ListItemIcon style={{minWidth: 0, paddingRight: 15, color: "#000", mt: 2}}>
                                        <StarIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Children - Spawned from matching couple" />
                                </ListItem>
                            </List>
                        </Box>
                    </Fade>
                </Grid>
            </>
        )
    }
}

export default Boxes
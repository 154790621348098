import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";

const backgroundImage = '/background.png';

const Root = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
        marginRight: '10%',
    },
    [theme.breakpoints.up('md')]: {
        marginRight: '25%',
    },
    [theme.breakpoints.up('lg')]: {
        marginRight: '50%',
    },
}));

export default function ProductHero() {

  return (
    <ProductHeroLayout
      sxBackground={{
          background: `url(${backgroundImage}) no-repeat center center fixed`,
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
          backgroundColor: '#C47F08FF', // Average color of the background image.
          backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
        <Root>
            <Typography
                variant="h1"
                sx={{
                    fontSize: { xs: '1.75rem', sm: '2.5rem', md: '2.8rem' },
                    fontWeight: 900,
                    color: '#fff',
                    lineHeight: 1.4
                }}
            >
                Welcome To The
                <Box component="span" sx={{ ml: 2, color: '#FFEA00' }}>
                    Alpha Centaurians Club
                </Box>
            </Typography>
            <Typography
                variant="h4"
                component="div"
                color="inherit"
                sx={{
                    fontSize: { xs: '1rem', md: '1.125rem' },
                    marginTop: 2,
                    fontWeight: 400,
                    lineHeight: 1.4
                }}
            >
                Join an exclusive club that is out of this world.
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                sx={{ minWidth: 150, marginTop: 5, marginLeft: 3 }}
            >
                SOLD OUT
            </Button>
        </Root>
    </ProductHeroLayout>
  );
}
